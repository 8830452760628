import type { BlockTypes } from '@etf1-interne/tf1info_types_news/blocks'
import type { ISponsorBanner } from '../../molecules/SponsorBanner'
import type { IPictureElementList } from '../../atoms/PictureElementList'

import { HeaderTitleBlock } from '../../molecules/HeaderTitleBlock'
import { PictureElementList } from '../../atoms/PictureElementList'
import { SponsorBanner } from '../../molecules/SponsorBanner'
import { mixins, theme } from '../../../styles'

export interface ITopicHeader {
  description: string
  pictures?: IPictureElementList
  sponsor?: ISponsorBanner
  title: string
}

export function TopicHeader({
  description,
  pictures,
  sponsor,
  title,
}: BlockTypes['topic-header']['data']): JSX.Element {
  return (
    <>
      <div className="TopicHeader">
        {pictures && (
          <div className="TopicHeader__Image">
            <PictureElementList
              {...pictures}
              lazyload={false}
              importance="high"
              fetchPriority="high"
              withPreload
            />
          </div>
        )}
        <HeaderTitleBlock
          title={title}
          subtitle={description}
          color={theme.cssVars.white}
          backgroundColor={theme.cssVars.deepBlue}
        />
        {sponsor && <SponsorBanner {...sponsor} withPreload theme="dark" />}
      </div>
      <style jsx>{`
        .TopicHeader {
          background-color: ${theme.cssVars.deepBlue};
        }

        .TopicHeader__Image {
          position: relative;
          height: 375px;
        }

        .TopicHeader__Image :global(picture) {
          width: 100%;
          height: 100%;
        }

        .TopicHeader__Image :global(img) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .TopicHeader :global(.HeaderTitleBlock) {
          padding: ${sponsor ? '25px 25px 0' : '25px'};
        }

        .TopicHeader :global(.SponsorBanner) {
          margin-bottom: 50px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .TopicHeader__Image {
            height: 460px;
            max-width: 2500px;
          }

          .TopicHeader :global(.SponsorBanner) {
            margin-bottom: 70px;
          }
        }
      `}</style>
    </>
  )
}
