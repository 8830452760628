import { theme } from '../../../styles'
import { Title } from '../../atoms/Title'
import { DescriptionHeader } from '../DescriptionHeader'

export interface IHeaderTitleBlock {
  title: string
  subtitle: string
  color?: string
  backgroundColor?: string
}

export function HeaderTitleBlock({
  title,
  subtitle,
  color,
  backgroundColor,
}: IHeaderTitleBlock): JSX.Element {
  return (
    <>
      <div className="HeaderTitleBlock flex justify-center items-center">
        <div className="HeaderTitleBlock__Content flex flex-column justify-center items-center">
          <Title color={color || theme.cssVars.fontColor} align="center">
            {title}
          </Title>
          {subtitle?.length ? (
            <DescriptionHeader
              text={subtitle}
              color={color || theme.cssVars.fontColor}
              backgroundColor={backgroundColor || theme.cssVars.bodyBackground}
            />
          ) : null}
        </div>
      </div>

      <style jsx>{`
        .HeaderTitleBlock {
          background-color: ${backgroundColor || theme.cssVars.bodyBackground};
          padding: 24px;
          width: 100%;
        }
        .HeaderTitleBlock__Content {
          max-width: ${theme.breakpoints.md}px;
          padding: 24px;
        }
      `}</style>
    </>
  )
}
